
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header{
	width:100%;
	min-width: $min-width;
	text-align:left;	
	order: 1;	
    -webkit-order: 1;	
}

.header {
	padding: 24px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 2;
	&-logo {
		img {
			max-width: 170px;
		}
	}
	ul {
		margin-left: 52px;
		margin-top: -6px;
		li {
			display: inline-block;
			margin: 0 14px;
			a {
				font-size: 16px;
				line-height: 24px;
				color: $grey1;
				&:hover {
					color: $black;
				}
			}
		}
	}
}

.btn-menu {
	color: #667788;
	cursor: pointer;
	display: none;
	height: 45px;
	width: 18px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	position: absolute;
    top: 10px;
    right: 17px;
	span{
		background-color: #2a4893;
		display: block;
		height: 2px;
		left: calc(50% - 11px);
		position: absolute;
		-ms-transform-origin: center;
		transform-origin: center;
		transition-duration: 86ms;
		transition-property: background-color,opacity,transform;
		transition-timing-function: ease-out;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		width: 18px;
		&:nth-child(1) {
			top: calc(50% - 7px);
		}
		&:nth-child(2) {
			top: calc(50% - 1px);
		}
		&:nth-child(3) {
			top: calc(50% + 5px);
		}
	}
	&.active{
		cursor: pointer;
		span{
			&:nth-child(1) {
				transform: translateY(5px) rotate(45deg);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:nth-child(3) {
				transform: translateY(-7px) rotate(-45deg);
			}
		}
	}
}



@media screen and (max-width: 1200px) {
	
}

@media screen and (max-width: 991px) {
	.header {
		.row {
			justify-content: space-between;
		}
	}
	.header ul {
		margin-left: 5px;
	}
}

@media screen and (max-width: 767px) {
	.header {
		padding: 18px 15px 18px 8px;
		ul.header-nav {
			display: none;
			margin-left: 0;
			text-align: center;
			li {
				display: block;
				margin: 10px 0;
			}
			&.active {
				display: flex;
				align-content: center;
				justify-content: center;
				flex-direction: column;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				min-height: 102vh;
				background-color: $white;
			}
		}
	}
	.btn-menu {
		display: block;
	}
}
