
/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer{
	text-align:left;
	width:100%;
	min-width: $min-width;
	order: 3;	
    -webkit-order: 3;	
}

.footer {
	padding-bottom: 80px;
	&-logo {
		margin-left: -15px;
		img {
			display: block;
			margin-bottom: 27px;
		}
	}
	&-copy {
		font-size: 12px;
		line-height: 18px;
		color: $grey2;
		max-width: 274px;
		display: block;
	}
	&-nav {
		margin-top: 7px;
		margin-right: 90px;
		span {
			display: block;
			font-weight: 500;
			font-size: 22px;
			line-height: 24px;
			margin-bottom: 36px;
			color: $blue;
		}
		li {
			margin-bottom: 16px;
			a {
				font-size: 16px;
				line-height: 24px;
				color: $grey1;
				&:hover {
					color: $black;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.footer-nav {
		margin-right: 40px;
	}
}

@media screen and (max-width: 991px) {
	.footer-logo {
		margin-bottom: 40px;
	}
	.footer-nav {
		margin-right: 0;
		width: 33%;
	}
	.footer-copy {
		max-width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.footer {
		padding-left: 8px;
		padding-right: 8px;
		padding-bottom: 32px;
	}
	.footer-nav {
		width: 50%;
		li a {
			font-size: 14px;
			line-height: 20px;
		}
	}
	.footer-logo img {
		max-width: 139px;
		margin-bottom: 12px;
	}
	.footer-nav span {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 15px;
	}
	.footer-logo {
		margin-bottom: 11px;
	}
}