/* reset */
/* main variables */
body { margin: 0px; padding: 0px; font-family: "Roboto", sans-serif; background-color: #fff; height: 100%; line-height: 1; position: relative; font-size: 16px !important; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

/*MAIN*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main { display: block; }

blockquote, q { quotes: none; }

blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }

table { border-collapse: collapse; border-width: 0px; padding: 0px; margin: 0px; }

html { height: 100%; -webkit-text-size-adjust: none; -ms-text-size-adjust: none; }

input, textarea { color: #333; font-family: "Roboto", sans-serif; outline: none; border-radius: 0; -moz-border-radius: 0; -webkit-border-radius: 0; -webkit-appearance: none; }

input[type="button"], input[type="submit"], button { cursor: pointer; }

td { margin: 0px; padding: 0px; }

form { padding: 0px; margin: 0px; }

a { color: #100da9; -webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -ms-transition: all 0.2s linear; -o-transition: all 0.2s linear; transition: all 0.2s linear; text-decoration: none; outline: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a, span, div, button { outline: none !important; }

input[type=submit], input[type=button], button { -webkit-appearance: none; outline: none; }

* { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

*:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.clearfix:after, .wrapper:after, .row:after { content: "."; display: block; height: 0; clear: both; visibility: hidden; }

img { max-width: 100%; }

ul, li { list-style: none; }

@font-face { font-family: 'Roboto'; src: url("../fonts/roboto-regular.eot"); src: url("../fonts/roboto-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-regular.woff") format("woff"), url("../fonts/roboto-regular.ttf") format("truetype"); font-weight: 400; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Roboto'; src: url("../fonts/roboto-medium.eot"); src: url("../fonts/roboto-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-medium.woff") format("woff"), url("../fonts/roboto-medium.ttf") format("truetype"); font-weight: 500; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'Poppins'; src: url("../fonts/poppins-medium.eot"); src: url("../fonts/poppins-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/poppins-medium.woff") format("woff"), url("../fonts/poppins-medium.ttf") format("truetype"); font-weight: 500; font-style: normal; font-stretch: normal; font-display: swap; }

@font-face { font-family: 'icomoon'; src: url("../fonts/icomoon/icomoon.eot?1cf6lm"); src: url("../fonts/icomoon/icomoon.eot?1cf6lm#iefix") format("embedded-opentype"), url("../fonts/icomoon/icomoon.ttf?1cf6lm") format("truetype"), url("../fonts/icomoon/icomoon.woff?1cf6lm") format("woff"), url("../fonts/icomoon/icomoon.svg?1cf6lm#icomoon") format("svg"); font-weight: normal; font-style: normal; font-display: block; }

[class^="icon-"], [class*=" icon-"] { /* use !important to prevent issues with browser extensions that change fonts */ font-family: 'icomoon' !important; speak: never; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; color: #282828; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.icon-document:before { content: "\e900"; }

.icon-dollar:before { content: "\e901"; }

.icon-folders:before { content: "\e902"; }

.icon-graduate-hat:before { content: "\e903"; }

.icon-help-wheel:before { content: "\e904"; }

.icon-interface:before { content: "\e905"; }

.icon-news:before { content: "\e906"; }

.icon-search:before { content: "\e907"; }

.icon-shopping:before { content: "\e908"; }

.icon-travel:before { content: "\e909"; }

.icon-travel2:before { content: "\e90a"; }

/*! Bootstrap Grid v4.5.2 (https://getbootstrap.com/) Copyright 2011-2020 The Bootstrap Authors Copyright 2011-2020 Twitter, Inc. Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE) */
html { box-sizing: border-box; -ms-overflow-style: scrollbar; }

*, ::after, ::before { box-sizing: inherit; }

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

@media (min-width: 576px) { .container, .container-sm { max-width: 540px; } }

@media (min-width: 768px) { .container, .container-md, .container-sm { max-width: 720px; } }

@media (min-width: 992px) { .container, .container-lg, .container-md, .container-sm { max-width: 960px; } }

@media (min-width: 1200px) { .container, .container-lg, .container-md, .container-sm, .container-xl { max-width: 1140px; } }

.row { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] { padding-right: 0; padding-left: 0; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }

.col { -ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%; }

.row-cols-1 > * { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

.row-cols-2 > * { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }

.row-cols-3 > * { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }

.row-cols-4 > * { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }

.row-cols-5 > * { -ms-flex: 0 0 20%; flex: 0 0 20%; max-width: 20%; }

.row-cols-6 > * { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }

.col-auto { -ms-flex: 0 0 auto; flex: 0 0 auto; width: auto; max-width: 100%; }

.col-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }

.col-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }

.col-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }

.col-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }

.col-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }

.col-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }

.col-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }

.col-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }

.col-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }

.col-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }

.col-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }

.col-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }

.order-first { -ms-flex-order: -1; order: -1; }

.order-last { -ms-flex-order: 13; order: 13; }

.order-0 { -ms-flex-order: 0; order: 0; }

.order-1 { -ms-flex-order: 1; order: 1; }

.order-2 { -ms-flex-order: 2; order: 2; }

.order-3 { -ms-flex-order: 3; order: 3; }

.order-4 { -ms-flex-order: 4; order: 4; }

.order-5 { -ms-flex-order: 5; order: 5; }

.order-6 { -ms-flex-order: 6; order: 6; }

.order-7 { -ms-flex-order: 7; order: 7; }

.order-8 { -ms-flex-order: 8; order: 8; }

.order-9 { -ms-flex-order: 9; order: 9; }

.order-10 { -ms-flex-order: 10; order: 10; }

.order-11 { -ms-flex-order: 11; order: 11; }

.order-12 { -ms-flex-order: 12; order: 12; }

.offset-1 { margin-left: 8.333333%; }

.offset-2 { margin-left: 16.666667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.333333%; }

.offset-5 { margin-left: 41.666667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.333333%; }

.offset-8 { margin-left: 66.666667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.333333%; }

.offset-11 { margin-left: 91.666667%; }

@media (min-width: 576px) { .col-sm { -ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%; }
  .row-cols-sm-1 > * { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .row-cols-sm-2 > * { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .row-cols-sm-3 > * { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .row-cols-sm-4 > * { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .row-cols-sm-5 > * { -ms-flex: 0 0 20%; flex: 0 0 20%; max-width: 20%; }
  .row-cols-sm-6 > * { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-sm-auto { -ms-flex: 0 0 auto; flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-sm-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-sm-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-sm-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-sm-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-sm-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-sm-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-sm-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-sm-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { -ms-flex-order: -1; order: -1; }
  .order-sm-last { -ms-flex-order: 13; order: 13; }
  .order-sm-0 { -ms-flex-order: 0; order: 0; }
  .order-sm-1 { -ms-flex-order: 1; order: 1; }
  .order-sm-2 { -ms-flex-order: 2; order: 2; }
  .order-sm-3 { -ms-flex-order: 3; order: 3; }
  .order-sm-4 { -ms-flex-order: 4; order: 4; }
  .order-sm-5 { -ms-flex-order: 5; order: 5; }
  .order-sm-6 { -ms-flex-order: 6; order: 6; }
  .order-sm-7 { -ms-flex-order: 7; order: 7; }
  .order-sm-8 { -ms-flex-order: 8; order: 8; }
  .order-sm-9 { -ms-flex-order: 9; order: 9; }
  .order-sm-10 { -ms-flex-order: 10; order: 10; }
  .order-sm-11 { -ms-flex-order: 11; order: 11; }
  .order-sm-12 { -ms-flex-order: 12; order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.333333%; }
  .offset-sm-2 { margin-left: 16.666667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.333333%; }
  .offset-sm-5 { margin-left: 41.666667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.333333%; }
  .offset-sm-8 { margin-left: 66.666667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.333333%; }
  .offset-sm-11 { margin-left: 91.666667%; } }

@media (min-width: 768px) { .col-md { -ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%; }
  .row-cols-md-1 > * { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .row-cols-md-2 > * { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .row-cols-md-3 > * { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .row-cols-md-4 > * { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .row-cols-md-5 > * { -ms-flex: 0 0 20%; flex: 0 0 20%; max-width: 20%; }
  .row-cols-md-6 > * { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-md-auto { -ms-flex: 0 0 auto; flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-md-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-md-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-md-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-md-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-md-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-md-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-md-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-md-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .order-md-first { -ms-flex-order: -1; order: -1; }
  .order-md-last { -ms-flex-order: 13; order: 13; }
  .order-md-0 { -ms-flex-order: 0; order: 0; }
  .order-md-1 { -ms-flex-order: 1; order: 1; }
  .order-md-2 { -ms-flex-order: 2; order: 2; }
  .order-md-3 { -ms-flex-order: 3; order: 3; }
  .order-md-4 { -ms-flex-order: 4; order: 4; }
  .order-md-5 { -ms-flex-order: 5; order: 5; }
  .order-md-6 { -ms-flex-order: 6; order: 6; }
  .order-md-7 { -ms-flex-order: 7; order: 7; }
  .order-md-8 { -ms-flex-order: 8; order: 8; }
  .order-md-9 { -ms-flex-order: 9; order: 9; }
  .order-md-10 { -ms-flex-order: 10; order: 10; }
  .order-md-11 { -ms-flex-order: 11; order: 11; }
  .order-md-12 { -ms-flex-order: 12; order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.333333%; }
  .offset-md-2 { margin-left: 16.666667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.333333%; }
  .offset-md-5 { margin-left: 41.666667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.333333%; }
  .offset-md-8 { margin-left: 66.666667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.333333%; }
  .offset-md-11 { margin-left: 91.666667%; } }

@media (min-width: 992px) { .col-lg { -ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%; }
  .row-cols-lg-1 > * { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .row-cols-lg-2 > * { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .row-cols-lg-3 > * { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .row-cols-lg-4 > * { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .row-cols-lg-5 > * { -ms-flex: 0 0 20%; flex: 0 0 20%; max-width: 20%; }
  .row-cols-lg-6 > * { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-lg-auto { -ms-flex: 0 0 auto; flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-lg-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-lg-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-lg-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-lg-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-lg-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-lg-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-lg-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-lg-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { -ms-flex-order: -1; order: -1; }
  .order-lg-last { -ms-flex-order: 13; order: 13; }
  .order-lg-0 { -ms-flex-order: 0; order: 0; }
  .order-lg-1 { -ms-flex-order: 1; order: 1; }
  .order-lg-2 { -ms-flex-order: 2; order: 2; }
  .order-lg-3 { -ms-flex-order: 3; order: 3; }
  .order-lg-4 { -ms-flex-order: 4; order: 4; }
  .order-lg-5 { -ms-flex-order: 5; order: 5; }
  .order-lg-6 { -ms-flex-order: 6; order: 6; }
  .order-lg-7 { -ms-flex-order: 7; order: 7; }
  .order-lg-8 { -ms-flex-order: 8; order: 8; }
  .order-lg-9 { -ms-flex-order: 9; order: 9; }
  .order-lg-10 { -ms-flex-order: 10; order: 10; }
  .order-lg-11 { -ms-flex-order: 11; order: 11; }
  .order-lg-12 { -ms-flex-order: 12; order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.333333%; }
  .offset-lg-2 { margin-left: 16.666667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.333333%; }
  .offset-lg-5 { margin-left: 41.666667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.333333%; }
  .offset-lg-8 { margin-left: 66.666667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.333333%; }
  .offset-lg-11 { margin-left: 91.666667%; } }

@media (min-width: 1200px) { .col-xl { -ms-flex-preferred-size: 0; flex-basis: 0; -ms-flex-positive: 1; flex-grow: 1; max-width: 100%; }
  .row-cols-xl-1 > * { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .row-cols-xl-2 > * { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .row-cols-xl-3 > * { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .row-cols-xl-4 > * { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .row-cols-xl-5 > * { -ms-flex: 0 0 20%; flex: 0 0 20%; max-width: 20%; }
  .row-cols-xl-6 > * { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-xl-auto { -ms-flex: 0 0 auto; flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { -ms-flex: 0 0 8.333333%; flex: 0 0 8.333333%; max-width: 8.333333%; }
  .col-xl-2 { -ms-flex: 0 0 16.666667%; flex: 0 0 16.666667%; max-width: 16.666667%; }
  .col-xl-3 { -ms-flex: 0 0 25%; flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { -ms-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
  .col-xl-5 { -ms-flex: 0 0 41.666667%; flex: 0 0 41.666667%; max-width: 41.666667%; }
  .col-xl-6 { -ms-flex: 0 0 50%; flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { -ms-flex: 0 0 58.333333%; flex: 0 0 58.333333%; max-width: 58.333333%; }
  .col-xl-8 { -ms-flex: 0 0 66.666667%; flex: 0 0 66.666667%; max-width: 66.666667%; }
  .col-xl-9 { -ms-flex: 0 0 75%; flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { -ms-flex: 0 0 83.333333%; flex: 0 0 83.333333%; max-width: 83.333333%; }
  .col-xl-11 { -ms-flex: 0 0 91.666667%; flex: 0 0 91.666667%; max-width: 91.666667%; }
  .col-xl-12 { -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { -ms-flex-order: -1; order: -1; }
  .order-xl-last { -ms-flex-order: 13; order: 13; }
  .order-xl-0 { -ms-flex-order: 0; order: 0; }
  .order-xl-1 { -ms-flex-order: 1; order: 1; }
  .order-xl-2 { -ms-flex-order: 2; order: 2; }
  .order-xl-3 { -ms-flex-order: 3; order: 3; }
  .order-xl-4 { -ms-flex-order: 4; order: 4; }
  .order-xl-5 { -ms-flex-order: 5; order: 5; }
  .order-xl-6 { -ms-flex-order: 6; order: 6; }
  .order-xl-7 { -ms-flex-order: 7; order: 7; }
  .order-xl-8 { -ms-flex-order: 8; order: 8; }
  .order-xl-9 { -ms-flex-order: 9; order: 9; }
  .order-xl-10 { -ms-flex-order: 10; order: 10; }
  .order-xl-11 { -ms-flex-order: 11; order: 11; }
  .order-xl-12 { -ms-flex-order: 12; order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.333333%; }
  .offset-xl-2 { margin-left: 16.666667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.333333%; }
  .offset-xl-5 { margin-left: 41.666667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.333333%; }
  .offset-xl-8 { margin-left: 66.666667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.333333%; }
  .offset-xl-11 { margin-left: 91.666667%; } }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: -ms-flexbox !important; display: flex !important; }

.d-inline-flex { display: -ms-inline-flexbox !important; display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: -ms-flexbox !important; display: flex !important; }
  .d-sm-inline-flex { display: -ms-inline-flexbox !important; display: inline-flex !important; } }

@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: -ms-flexbox !important; display: flex !important; }
  .d-md-inline-flex { display: -ms-inline-flexbox !important; display: inline-flex !important; } }

@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: -ms-flexbox !important; display: flex !important; }
  .d-lg-inline-flex { display: -ms-inline-flexbox !important; display: inline-flex !important; } }

@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: -ms-flexbox !important; display: flex !important; }
  .d-xl-inline-flex { display: -ms-inline-flexbox !important; display: inline-flex !important; } }

@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: -ms-flexbox !important; display: flex !important; }
  .d-print-inline-flex { display: -ms-inline-flexbox !important; display: inline-flex !important; } }

.flex-row { -ms-flex-direction: row !important; flex-direction: row !important; }

.flex-column { -ms-flex-direction: column !important; flex-direction: column !important; }

.flex-row-reverse { -ms-flex-direction: row-reverse !important; flex-direction: row-reverse !important; }

.flex-column-reverse { -ms-flex-direction: column-reverse !important; flex-direction: column-reverse !important; }

.flex-wrap { -ms-flex-wrap: wrap !important; flex-wrap: wrap !important; }

.flex-nowrap { -ms-flex-wrap: nowrap !important; flex-wrap: nowrap !important; }

.flex-wrap-reverse { -ms-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important; }

.flex-fill { -ms-flex: 1 1 auto !important; flex: 1 1 auto !important; }

.flex-grow-0 { -ms-flex-positive: 0 !important; flex-grow: 0 !important; }

.flex-grow-1 { -ms-flex-positive: 1 !important; flex-grow: 1 !important; }

.flex-shrink-0 { -ms-flex-negative: 0 !important; flex-shrink: 0 !important; }

.flex-shrink-1 { -ms-flex-negative: 1 !important; flex-shrink: 1 !important; }

.justify-content-start { -ms-flex-pack: start !important; justify-content: flex-start !important; }

.justify-content-end { -ms-flex-pack: end !important; justify-content: flex-end !important; }

.justify-content-center { -ms-flex-pack: center !important; justify-content: center !important; }

.justify-content-between { -ms-flex-pack: justify !important; justify-content: space-between !important; }

.justify-content-around { -ms-flex-pack: distribute !important; justify-content: space-around !important; }

.align-items-start { -ms-flex-align: start !important; align-items: flex-start !important; }

.align-items-end { -ms-flex-align: end !important; align-items: flex-end !important; }

.align-items-center { -ms-flex-align: center !important; align-items: center !important; }

.align-items-baseline { -ms-flex-align: baseline !important; align-items: baseline !important; }

.align-items-stretch { -ms-flex-align: stretch !important; align-items: stretch !important; }

.align-content-start { -ms-flex-line-pack: start !important; align-content: flex-start !important; }

.align-content-end { -ms-flex-line-pack: end !important; align-content: flex-end !important; }

.align-content-center { -ms-flex-line-pack: center !important; align-content: center !important; }

.align-content-between { -ms-flex-line-pack: justify !important; align-content: space-between !important; }

.align-content-around { -ms-flex-line-pack: distribute !important; align-content: space-around !important; }

.align-content-stretch { -ms-flex-line-pack: stretch !important; align-content: stretch !important; }

.align-self-auto { -ms-flex-item-align: auto !important; align-self: auto !important; }

.align-self-start { -ms-flex-item-align: start !important; align-self: flex-start !important; }

.align-self-end { -ms-flex-item-align: end !important; align-self: flex-end !important; }

.align-self-center { -ms-flex-item-align: center !important; align-self: center !important; }

.align-self-baseline { -ms-flex-item-align: baseline !important; align-self: baseline !important; }

.align-self-stretch { -ms-flex-item-align: stretch !important; align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { -ms-flex-direction: row !important; flex-direction: row !important; }
  .flex-sm-column { -ms-flex-direction: column !important; flex-direction: column !important; }
  .flex-sm-row-reverse { -ms-flex-direction: row-reverse !important; flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { -ms-flex-direction: column-reverse !important; flex-direction: column-reverse !important; }
  .flex-sm-wrap { -ms-flex-wrap: wrap !important; flex-wrap: wrap !important; }
  .flex-sm-nowrap { -ms-flex-wrap: nowrap !important; flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { -ms-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { -ms-flex: 1 1 auto !important; flex: 1 1 auto !important; }
  .flex-sm-grow-0 { -ms-flex-positive: 0 !important; flex-grow: 0 !important; }
  .flex-sm-grow-1 { -ms-flex-positive: 1 !important; flex-grow: 1 !important; }
  .flex-sm-shrink-0 { -ms-flex-negative: 0 !important; flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { -ms-flex-negative: 1 !important; flex-shrink: 1 !important; }
  .justify-content-sm-start { -ms-flex-pack: start !important; justify-content: flex-start !important; }
  .justify-content-sm-end { -ms-flex-pack: end !important; justify-content: flex-end !important; }
  .justify-content-sm-center { -ms-flex-pack: center !important; justify-content: center !important; }
  .justify-content-sm-between { -ms-flex-pack: justify !important; justify-content: space-between !important; }
  .justify-content-sm-around { -ms-flex-pack: distribute !important; justify-content: space-around !important; }
  .align-items-sm-start { -ms-flex-align: start !important; align-items: flex-start !important; }
  .align-items-sm-end { -ms-flex-align: end !important; align-items: flex-end !important; }
  .align-items-sm-center { -ms-flex-align: center !important; align-items: center !important; }
  .align-items-sm-baseline { -ms-flex-align: baseline !important; align-items: baseline !important; }
  .align-items-sm-stretch { -ms-flex-align: stretch !important; align-items: stretch !important; }
  .align-content-sm-start { -ms-flex-line-pack: start !important; align-content: flex-start !important; }
  .align-content-sm-end { -ms-flex-line-pack: end !important; align-content: flex-end !important; }
  .align-content-sm-center { -ms-flex-line-pack: center !important; align-content: center !important; }
  .align-content-sm-between { -ms-flex-line-pack: justify !important; align-content: space-between !important; }
  .align-content-sm-around { -ms-flex-line-pack: distribute !important; align-content: space-around !important; }
  .align-content-sm-stretch { -ms-flex-line-pack: stretch !important; align-content: stretch !important; }
  .align-self-sm-auto { -ms-flex-item-align: auto !important; align-self: auto !important; }
  .align-self-sm-start { -ms-flex-item-align: start !important; align-self: flex-start !important; }
  .align-self-sm-end { -ms-flex-item-align: end !important; align-self: flex-end !important; }
  .align-self-sm-center { -ms-flex-item-align: center !important; align-self: center !important; }
  .align-self-sm-baseline { -ms-flex-item-align: baseline !important; align-self: baseline !important; }
  .align-self-sm-stretch { -ms-flex-item-align: stretch !important; align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { -ms-flex-direction: row !important; flex-direction: row !important; }
  .flex-md-column { -ms-flex-direction: column !important; flex-direction: column !important; }
  .flex-md-row-reverse { -ms-flex-direction: row-reverse !important; flex-direction: row-reverse !important; }
  .flex-md-column-reverse { -ms-flex-direction: column-reverse !important; flex-direction: column-reverse !important; }
  .flex-md-wrap { -ms-flex-wrap: wrap !important; flex-wrap: wrap !important; }
  .flex-md-nowrap { -ms-flex-wrap: nowrap !important; flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { -ms-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important; }
  .flex-md-fill { -ms-flex: 1 1 auto !important; flex: 1 1 auto !important; }
  .flex-md-grow-0 { -ms-flex-positive: 0 !important; flex-grow: 0 !important; }
  .flex-md-grow-1 { -ms-flex-positive: 1 !important; flex-grow: 1 !important; }
  .flex-md-shrink-0 { -ms-flex-negative: 0 !important; flex-shrink: 0 !important; }
  .flex-md-shrink-1 { -ms-flex-negative: 1 !important; flex-shrink: 1 !important; }
  .justify-content-md-start { -ms-flex-pack: start !important; justify-content: flex-start !important; }
  .justify-content-md-end { -ms-flex-pack: end !important; justify-content: flex-end !important; }
  .justify-content-md-center { -ms-flex-pack: center !important; justify-content: center !important; }
  .justify-content-md-between { -ms-flex-pack: justify !important; justify-content: space-between !important; }
  .justify-content-md-around { -ms-flex-pack: distribute !important; justify-content: space-around !important; }
  .align-items-md-start { -ms-flex-align: start !important; align-items: flex-start !important; }
  .align-items-md-end { -ms-flex-align: end !important; align-items: flex-end !important; }
  .align-items-md-center { -ms-flex-align: center !important; align-items: center !important; }
  .align-items-md-baseline { -ms-flex-align: baseline !important; align-items: baseline !important; }
  .align-items-md-stretch { -ms-flex-align: stretch !important; align-items: stretch !important; }
  .align-content-md-start { -ms-flex-line-pack: start !important; align-content: flex-start !important; }
  .align-content-md-end { -ms-flex-line-pack: end !important; align-content: flex-end !important; }
  .align-content-md-center { -ms-flex-line-pack: center !important; align-content: center !important; }
  .align-content-md-between { -ms-flex-line-pack: justify !important; align-content: space-between !important; }
  .align-content-md-around { -ms-flex-line-pack: distribute !important; align-content: space-around !important; }
  .align-content-md-stretch { -ms-flex-line-pack: stretch !important; align-content: stretch !important; }
  .align-self-md-auto { -ms-flex-item-align: auto !important; align-self: auto !important; }
  .align-self-md-start { -ms-flex-item-align: start !important; align-self: flex-start !important; }
  .align-self-md-end { -ms-flex-item-align: end !important; align-self: flex-end !important; }
  .align-self-md-center { -ms-flex-item-align: center !important; align-self: center !important; }
  .align-self-md-baseline { -ms-flex-item-align: baseline !important; align-self: baseline !important; }
  .align-self-md-stretch { -ms-flex-item-align: stretch !important; align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { -ms-flex-direction: row !important; flex-direction: row !important; }
  .flex-lg-column { -ms-flex-direction: column !important; flex-direction: column !important; }
  .flex-lg-row-reverse { -ms-flex-direction: row-reverse !important; flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { -ms-flex-direction: column-reverse !important; flex-direction: column-reverse !important; }
  .flex-lg-wrap { -ms-flex-wrap: wrap !important; flex-wrap: wrap !important; }
  .flex-lg-nowrap { -ms-flex-wrap: nowrap !important; flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { -ms-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { -ms-flex: 1 1 auto !important; flex: 1 1 auto !important; }
  .flex-lg-grow-0 { -ms-flex-positive: 0 !important; flex-grow: 0 !important; }
  .flex-lg-grow-1 { -ms-flex-positive: 1 !important; flex-grow: 1 !important; }
  .flex-lg-shrink-0 { -ms-flex-negative: 0 !important; flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { -ms-flex-negative: 1 !important; flex-shrink: 1 !important; }
  .justify-content-lg-start { -ms-flex-pack: start !important; justify-content: flex-start !important; }
  .justify-content-lg-end { -ms-flex-pack: end !important; justify-content: flex-end !important; }
  .justify-content-lg-center { -ms-flex-pack: center !important; justify-content: center !important; }
  .justify-content-lg-between { -ms-flex-pack: justify !important; justify-content: space-between !important; }
  .justify-content-lg-around { -ms-flex-pack: distribute !important; justify-content: space-around !important; }
  .align-items-lg-start { -ms-flex-align: start !important; align-items: flex-start !important; }
  .align-items-lg-end { -ms-flex-align: end !important; align-items: flex-end !important; }
  .align-items-lg-center { -ms-flex-align: center !important; align-items: center !important; }
  .align-items-lg-baseline { -ms-flex-align: baseline !important; align-items: baseline !important; }
  .align-items-lg-stretch { -ms-flex-align: stretch !important; align-items: stretch !important; }
  .align-content-lg-start { -ms-flex-line-pack: start !important; align-content: flex-start !important; }
  .align-content-lg-end { -ms-flex-line-pack: end !important; align-content: flex-end !important; }
  .align-content-lg-center { -ms-flex-line-pack: center !important; align-content: center !important; }
  .align-content-lg-between { -ms-flex-line-pack: justify !important; align-content: space-between !important; }
  .align-content-lg-around { -ms-flex-line-pack: distribute !important; align-content: space-around !important; }
  .align-content-lg-stretch { -ms-flex-line-pack: stretch !important; align-content: stretch !important; }
  .align-self-lg-auto { -ms-flex-item-align: auto !important; align-self: auto !important; }
  .align-self-lg-start { -ms-flex-item-align: start !important; align-self: flex-start !important; }
  .align-self-lg-end { -ms-flex-item-align: end !important; align-self: flex-end !important; }
  .align-self-lg-center { -ms-flex-item-align: center !important; align-self: center !important; }
  .align-self-lg-baseline { -ms-flex-item-align: baseline !important; align-self: baseline !important; }
  .align-self-lg-stretch { -ms-flex-item-align: stretch !important; align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { -ms-flex-direction: row !important; flex-direction: row !important; }
  .flex-xl-column { -ms-flex-direction: column !important; flex-direction: column !important; }
  .flex-xl-row-reverse { -ms-flex-direction: row-reverse !important; flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { -ms-flex-direction: column-reverse !important; flex-direction: column-reverse !important; }
  .flex-xl-wrap { -ms-flex-wrap: wrap !important; flex-wrap: wrap !important; }
  .flex-xl-nowrap { -ms-flex-wrap: nowrap !important; flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { -ms-flex-wrap: wrap-reverse !important; flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { -ms-flex: 1 1 auto !important; flex: 1 1 auto !important; }
  .flex-xl-grow-0 { -ms-flex-positive: 0 !important; flex-grow: 0 !important; }
  .flex-xl-grow-1 { -ms-flex-positive: 1 !important; flex-grow: 1 !important; }
  .flex-xl-shrink-0 { -ms-flex-negative: 0 !important; flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { -ms-flex-negative: 1 !important; flex-shrink: 1 !important; }
  .justify-content-xl-start { -ms-flex-pack: start !important; justify-content: flex-start !important; }
  .justify-content-xl-end { -ms-flex-pack: end !important; justify-content: flex-end !important; }
  .justify-content-xl-center { -ms-flex-pack: center !important; justify-content: center !important; }
  .justify-content-xl-between { -ms-flex-pack: justify !important; justify-content: space-between !important; }
  .justify-content-xl-around { -ms-flex-pack: distribute !important; justify-content: space-around !important; }
  .align-items-xl-start { -ms-flex-align: start !important; align-items: flex-start !important; }
  .align-items-xl-end { -ms-flex-align: end !important; align-items: flex-end !important; }
  .align-items-xl-center { -ms-flex-align: center !important; align-items: center !important; }
  .align-items-xl-baseline { -ms-flex-align: baseline !important; align-items: baseline !important; }
  .align-items-xl-stretch { -ms-flex-align: stretch !important; align-items: stretch !important; }
  .align-content-xl-start { -ms-flex-line-pack: start !important; align-content: flex-start !important; }
  .align-content-xl-end { -ms-flex-line-pack: end !important; align-content: flex-end !important; }
  .align-content-xl-center { -ms-flex-line-pack: center !important; align-content: center !important; }
  .align-content-xl-between { -ms-flex-line-pack: justify !important; align-content: space-between !important; }
  .align-content-xl-around { -ms-flex-line-pack: distribute !important; align-content: space-around !important; }
  .align-content-xl-stretch { -ms-flex-line-pack: stretch !important; align-content: stretch !important; }
  .align-self-xl-auto { -ms-flex-item-align: auto !important; align-self: auto !important; }
  .align-self-xl-start { -ms-flex-item-align: start !important; align-self: flex-start !important; }
  .align-self-xl-end { -ms-flex-item-align: end !important; align-self: flex-end !important; }
  .align-self-xl-center { -ms-flex-item-align: center !important; align-self: center !important; }
  .align-self-xl-baseline { -ms-flex-item-align: baseline !important; align-self: baseline !important; }
  .align-self-xl-stretch { -ms-flex-item-align: stretch !important; align-self: stretch !important; } }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-n1 { margin: -0.25rem !important; }

.mt-n1, .my-n1 { margin-top: -0.25rem !important; }

.mr-n1, .mx-n1 { margin-right: -0.25rem !important; }

.mb-n1, .my-n1 { margin-bottom: -0.25rem !important; }

.ml-n1, .mx-n1 { margin-left: -0.25rem !important; }

.m-n2 { margin: -0.5rem !important; }

.mt-n2, .my-n2 { margin-top: -0.5rem !important; }

.mr-n2, .mx-n2 { margin-right: -0.5rem !important; }

.mb-n2, .my-n2 { margin-bottom: -0.5rem !important; }

.ml-n2, .mx-n2 { margin-left: -0.5rem !important; }

.m-n3 { margin: -1rem !important; }

.mt-n3, .my-n3 { margin-top: -1rem !important; }

.mr-n3, .mx-n3 { margin-right: -1rem !important; }

.mb-n3, .my-n3 { margin-bottom: -1rem !important; }

.ml-n3, .mx-n3 { margin-left: -1rem !important; }

.m-n4 { margin: -1.5rem !important; }

.mt-n4, .my-n4 { margin-top: -1.5rem !important; }

.mr-n4, .mx-n4 { margin-right: -1.5rem !important; }

.mb-n4, .my-n4 { margin-bottom: -1.5rem !important; }

.ml-n4, .mx-n4 { margin-left: -1.5rem !important; }

.m-n5 { margin: -3rem !important; }

.mt-n5, .my-n5 { margin-top: -3rem !important; }

.mr-n5, .mx-n5 { margin-right: -3rem !important; }

.mb-n5, .my-n5 { margin-bottom: -3rem !important; }

.ml-n5, .mx-n5 { margin-left: -3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-n1 { margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 { margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 { margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 { margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 { margin-left: -0.25rem !important; }
  .m-sm-n2 { margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 { margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 { margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 { margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 { margin-left: -0.5rem !important; }
  .m-sm-n3 { margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 { margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 { margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 { margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 { margin-left: -1rem !important; }
  .m-sm-n4 { margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 { margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 { margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 { margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 { margin-left: -1.5rem !important; }
  .m-sm-n5 { margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 { margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 { margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 { margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 { margin-left: -3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }

@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-n1 { margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 { margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 { margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 { margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 { margin-left: -0.25rem !important; }
  .m-md-n2 { margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 { margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 { margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 { margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 { margin-left: -0.5rem !important; }
  .m-md-n3 { margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 { margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 { margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 { margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 { margin-left: -1rem !important; }
  .m-md-n4 { margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 { margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 { margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 { margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 { margin-left: -1.5rem !important; }
  .m-md-n5 { margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 { margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 { margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 { margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 { margin-left: -3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }

@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-n1 { margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 { margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 { margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 { margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 { margin-left: -0.25rem !important; }
  .m-lg-n2 { margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 { margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 { margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 { margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 { margin-left: -0.5rem !important; }
  .m-lg-n3 { margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 { margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 { margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 { margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 { margin-left: -1rem !important; }
  .m-lg-n4 { margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 { margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 { margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 { margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 { margin-left: -1.5rem !important; }
  .m-lg-n5 { margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 { margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 { margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 { margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 { margin-left: -3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }

@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-n1 { margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 { margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 { margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 { margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 { margin-left: -0.25rem !important; }
  .m-xl-n2 { margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 { margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 { margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 { margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 { margin-left: -0.5rem !important; }
  .m-xl-n3 { margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 { margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 { margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 { margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 { margin-left: -1rem !important; }
  .m-xl-n4 { margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 { margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 { margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 { margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 { margin-left: -1.5rem !important; }
  .m-xl-n5 { margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 { margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 { margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 { margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 { margin-left: -3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }

/*# sourceMappingURL=bootstrap-grid.min.css.map */
/*
================================================================================
|                                     HEADER                                   |
================================================================================
*/
header { width: 100%; min-width: 320px; text-align: left; order: 1; -webkit-order: 1; }

.header { padding: 24px 0; position: absolute; top: 0; left: 0; width: 100%; z-index: 2; }

.header-logo img { max-width: 170px; }

.header ul { margin-left: 52px; margin-top: -6px; }

.header ul li { display: inline-block; margin: 0 14px; }

.header ul li a { font-size: 16px; line-height: 24px; color: #6D7589; }

.header ul li a:hover { color: #282828; }

.btn-menu { color: #667788; cursor: pointer; display: none; height: 45px; width: 18px; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); position: absolute; top: 10px; right: 17px; }

.btn-menu span { background-color: #2a4893; display: block; height: 2px; left: calc(50% - 11px); position: absolute; -ms-transform-origin: center; transform-origin: center; transition-duration: 86ms; transition-property: background-color,opacity,transform; transition-timing-function: ease-out; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); width: 18px; }

.btn-menu span:nth-child(1) { top: calc(50% - 7px); }

.btn-menu span:nth-child(2) { top: calc(50% - 1px); }

.btn-menu span:nth-child(3) { top: calc(50% + 5px); }

.btn-menu.active { cursor: pointer; }

.btn-menu.active span:nth-child(1) { transform: translateY(5px) rotate(45deg); }

.btn-menu.active span:nth-child(2) { opacity: 0; }

.btn-menu.active span:nth-child(3) { transform: translateY(-7px) rotate(-45deg); }

@media screen and (max-width: 991px) { .header .row { justify-content: space-between; }
  .header ul { margin-left: 5px; } }

@media screen and (max-width: 767px) { .header { padding: 18px 15px 18px 8px; }
  .header ul.header-nav { display: none; margin-left: 0; text-align: center; }
  .header ul.header-nav li { display: block; margin: 10px 0; }
  .header ul.header-nav.active { display: flex; align-content: center; justify-content: center; flex-direction: column; position: absolute; top: 0; left: 0; width: 100%; min-height: 102vh; background-color: #fff; }
  .btn-menu { display: block; } }

/*
================================================================================
|                                     FOOTER                                   |
================================================================================
*/
footer { text-align: left; width: 100%; min-width: 320px; order: 3; -webkit-order: 3; }

.footer { padding-bottom: 80px; }

.footer-logo { margin-left: -15px; }

.footer-logo img { display: block; margin-bottom: 27px; }

.footer-copy { font-size: 12px; line-height: 18px; color: #B6BAC4; max-width: 274px; display: block; }

.footer-nav { margin-top: 7px; margin-right: 90px; }

.footer-nav span { display: block; font-weight: 500; font-size: 22px; line-height: 24px; margin-bottom: 36px; color: #2A4893; }

.footer-nav li { margin-bottom: 16px; }

.footer-nav li a { font-size: 16px; line-height: 24px; color: #6D7589; }

.footer-nav li a:hover { color: #282828; }

@media screen and (max-width: 1200px) { .footer-nav { margin-right: 40px; } }

@media screen and (max-width: 991px) { .footer-logo { margin-bottom: 40px; }
  .footer-nav { margin-right: 0; width: 33%; }
  .footer-copy { max-width: 100%; } }

@media screen and (max-width: 767px) { .footer { padding-left: 8px; padding-right: 8px; padding-bottom: 32px; }
  .footer-nav { width: 50%; }
  .footer-nav li a { font-size: 14px; line-height: 20px; }
  .footer-logo img { max-width: 139px; margin-bottom: 12px; }
  .footer-nav span { font-size: 18px; line-height: 24px; margin-bottom: 15px; }
  .footer-logo { margin-bottom: 11px; } }

.main-wrapper { padding: 0 0 0 0; min-width: 320px; width: 100%; position: relative; overflow: hidden; min-height: 100%; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-direction: column; -webkit-flex-direction: column; -ms-flex-direction: column; justify-content: flex-start; -webkit-justify-content: flex-start; -ms-flex-pack: start; }

.wrapper { min-width: 320px; max-width: 1200px; padding: 0 40px 0 40px; margin: 0 auto; position: relative; }

/* titles */
p { font-size: 16px; line-height: 24px; color: #282828; font-weight: 400; }

h1 { font-size: 60px; line-height: 64px; color: #282828; font-family: "Poppins", sans-serif; font-weight: 500; letter-spacing: .4px; }

h2 { font-size: 48px; line-height: 60px; color: #282828; font-family: "Poppins", sans-serif; font-weight: 500; }

h3 { font-size: 22px; line-height: 24px; color: #282828; font-family: "Poppins", sans-serif; font-weight: 500; }

h4 { font-size: 22px; line-height: 24px; color: #282828; font-family: "Poppins", sans-serif; font-weight: 500; }

h5 { font-size: 22px; line-height: 24px; color: #282828; font-family: "Poppins", sans-serif; font-weight: 500; }

/* text position */
.text-left { text-align: left !important; }

.text-center { text-align: center !important; }

.text-right { text-align: right !important; }

.nowrap { white-space: nowrap !important; }

/* loader */
.loaded .main-wrapper { visibility: hidden; }

.icon-load { background: url(../img/loader.gif) no-repeat left top; width: 40px; height: 40px; position: fixed; left: 50%; top: 50%; margin-left: -20px; margin-left: -20px; display: none; }

.loaded .icon-load { display: block; }

/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content { min-width: 320px; text-align: left; width: 100%; order: 2; -webkit-order: 2; flex-grow: 1; -webkit-flex-grow: 1; }

.btn { display: inline-block; line-height: normal; text-align: center; position: relative; transition: all 0.2s linear; background-color: #FE4F58; border: none; outline: none; padding: 12px 51px; color: #ffffff; border-radius: 3px; }

.btn:hover { background-color: #E84149; }

.btn-blue { background-color: #2A4893; }

.btn-blue:hover { background-color: #1D3777; }

.form-control { background: #fff; box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); border-radius: 6px; height: 70px; line-height: 70px; padding: 0 16px; border: none; outline: none; width: 100%; font-size: 16px; }

.form-control::placeholder { color: #B6BAC4; opacity: 1; }

.form-control:-ms-input-placeholder { color: #B6BAC4; }

.form-control::-ms-input-placeholder { color: #B6BAC4; }

.main-block { position: relative; padding-top: 160px; padding-bottom: 241px; z-index: 1; }

.main-block:after { content: ''; width: 100%; height: 140%; position: absolute; left: 0; top: 0; background: linear-gradient(180deg, #EFEFEF 0%, #FFFFFF 100%); transform: rotate(-180deg) skewY(-5deg); z-index: -1; }

.main-block__content { max-width: 562px; z-index: 11; }

.main-block__content h1 { color: #2A4893; margin-bottom: 8px; }

.main-block__content span { font-size: 18px; line-height: 26px; color: #6D7589; display: block; margin-bottom: 40px; letter-spacing: .05px; }

.main-block__img { position: absolute; left: 43%; top: 0; z-index: 1; object-fit: cover; width: 100%; }

.box-field { position: relative; }

.box-field__search { max-width: 469px; }

.box-field__search span { display: none; }

.box-field__search button { background-color: transparent; box-shadow: none; outline: none; border: none; position: absolute; right: 8px; top: 8px; background: #FE4F58; border-radius: 3px; width: 54px; height: 54px; display: flex; align-self: center; justify-content: center; transition: all 0.2s linear; }

.box-field__search button i { color: #fff; font-size: 30px; margin: auto; }

.box-field__search button:hover { background-color: #E84149; }

.box-field__search .form-control { padding-right: 70px; }

.box-field__search .form-control::-webkit-search-decoration, .box-field__search .form-control::-webkit-search-cancel-button, .box-field__search .form-control::-webkit-search-results-button, .box-field__search .form-control::-webkit-search-results-decoration { display: none; }

.states-block { position: relative; z-index: 1; }

.states-block:after { z-index: -1; position: absolute; top: -35px; left: 0; width: 100%; height: 110%; background-color: #FE4F58; content: ''; transform: skewY(-5deg); transform-origin: 100%; }

.states-block__content { background: #fff; box-shadow: 0px 42px 62px rgba(0, 0, 0, 0.1); border-radius: 6px; padding: 80px 44px 72px; }

.states-block__content h2 { color: #2A4893; }

.states-block__content p { color: #6D7589; max-width: 505px; margin: auto; }

.states-block__content p br { display: none; }

.states-block__content ul { margin-top: 32px; display: flex; flex-wrap: wrap; margin-left: -12px; margin-right: -12px; }

.states-block__content ul li { width: calc(25% - 24px); margin-left: 12px; margin-right: 12px; }

.states-block__content ul li a { border-radius: 32px; padding: 8px; display: flex; align-items: center; font-size: 18px; line-height: 24px; color: #000; }

.states-block__content ul li a:hover { background-color: #F3F4F4; }

.states-block__content ul li a img { width: 32px; height: 32px; margin-right: 18px; }

.derectories { padding-top: 96px; padding-bottom: 190px; color: #fff; position: relative; z-index: 1; }

.derectories:after { z-index: -1; position: absolute; top: 50px; left: 0; width: 100%; height: 78.5%; background-color: #2A4893; content: ''; transform: skewY(-5deg); transform-origin: 100%; }

.derectories h2 { color: #fff; font-size: 52px; line-height: 70px; }

.derectories p { color: #fff; max-width: 650px; margin: auto; font-size: 18px; line-height: 24px; opacity: 0.7; }

.derectories-items { margin-top: 59px; }

.derectories-item { display: flex; margin-bottom: 64px; padding: 30px 24px 24px 20px; margin-bottom: 10px; border-radius: 4px; }

.derectories-item__icon { width: 40px; margin-right: 16px; margin-top: -7px; }

.derectories-item__icon i { font-size: 38px; }

.derectories-item__info span { font-weight: 500; font-size: 22px; line-height: 24px; display: block; margin-bottom: 8px; color: #fff; }

.derectories-item__info p { opacity: 0.6; font-size: 16px; line-height: 24px; }

.derectories-items:nth-of-type(2) .derectories-item { padding-left: 23px; }

@media screen and (min-width: 2000px) { .main-block__img { left: 42.6%; }
  .states-block:after { top: -65px; }
  .derectories:after { top: 23px; } }

@media screen and (max-width: 1600px) { .main-block__img { right: -53.4%; left: auto; width: auto; }
  .states-block:after { top: -19px; }
  .derectories:after { top: 72px; } }

@media screen and (max-width: 1200px) { .main-block__img { right: -100%; } }

@media screen and (max-width: 991px) { .main-block__img { right: -120%; }
  .states-block__content ul li { width: calc(50% - 24px); }
  .derectories-items { margin-top: 0px; }
  .derectories-items:first-of-type { margin-top: 32px; }
  .derectories:after { height: 88.5%; } }

@media screen and (max-width: 800px) { .main-block__img { right: -140%; } }

@media screen and (max-width: 767px) { h1 { font-size: 32px; line-height: 42px; }
  .box-field__search { max-width: 100%; }
  .header ul.header-nav, .main-block__content { padding-left: 15px; padding-right: 15px; }
  .scroll-off { overflow: hidden; }
  .main-block { padding-top: 113px; padding-bottom: 276px; }
  .main-block__content span { font-size: 16px; line-height: 24px; }
  .main-block__content h1 { width: 98%; }
  .main-block__img { right: 0; top: auto; bottom: -2px; width: 100%; max-width: 320px; }
  h2 { font-size: 32px; line-height: 42px; }
  .states-block__content p, .derectories p, p { font-size: 14px; line-height: 20px; }
  .states-block { padding: 8px; background-color: #FE4F58; }
  .states-block:after { top: 0; }
  .states-block__content { padding: 24px 10px 34px; }
  .states-block__content ul li a { font-size: 14px; line-height: 20px; padding: 7px; }
  .states-block__content ul li a img { width: 16px; height: 16px; margin-right: 7px; }
  .states-block__content ul { margin: 0; margin-top: 13px; }
  .states-block__content ul li { width: 50%; margin: 0; }
  .derectories h2 { font-size: 32px; line-height: 42px; }
  .derectories { padding: 33px 0 56px; }
  .derectories p { width: 96%; }
  .derectories:after { top: 69px; }
  .derectories-item__info span { font-size: 18px; line-height: 24px; }
  .derectories-item { padding: 0; margin-bottom: 25px; }
  .derectories-item__icon { margin-right: 5px; margin-top: 2px; margin-left: -9px; }
  .derectories-item__icon i { font-size: 32px; }
  .derectories-item__info p { margin-left: 2px; }
  .derectories-items:nth-of-type(2) .derectories-item { padding-left: 0; }
  .main-block { background: #fff; }
  .states-block__content p br { display: block; }
  .main-block__content { z-index: 11; } }

@media screen and (max-width: 460px) { .derectories:after { height: 92.3%; }
  .main-block__img { max-width: 100%; position: relative; margin-top: 58px; }
  .main-block { padding-bottom: 0; }
  .box-field__search { padding: 8px; background: #FFFFFF; box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); border-radius: 6px; }
  .box-field__search .form-control { box-shadow: none; height: 54px; padding: 0 11px; }
  .main-block__content span { margin-bottom: 13px; }
  .box-field__search button { position: relative; right: auto; width: 100%; display: flex; justify-content: center; align-items: center; height: 40px; border-radius: 3px; top: auto; margin-top: 11px; }
  .box-field__search button i { display: none; }
  .box-field__search button span { display: block; font-size: 16px; line-height: 24px; color: #FFFFFF; margin-bottom: 0; } }
