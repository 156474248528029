@font-face {
	font-family: 'Roboto';
	src: url('../fonts/roboto-regular.eot');
	src: url('../fonts/roboto-regular.eot?#iefix') format('embedded-opentype'), 
	url('../fonts/roboto-regular.woff') format('woff'), 
	url('../fonts/roboto-regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto';
	src: url('../fonts/roboto-medium.eot');
	src: url('../fonts/roboto-medium.eot?#iefix') format('embedded-opentype'), 
	url('../fonts/roboto-medium.woff') format('woff'), 
	url('../fonts/roboto-medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/poppins-medium.eot');
	src: url('../fonts/poppins-medium.eot?#iefix') format('embedded-opentype'), 
	url('../fonts/poppins-medium.woff') format('woff'), 
	url('../fonts/poppins-medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: swap;
}

@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon/icomoon.eot?1cf6lm');
	src: url('../fonts/icomoon/icomoon.eot?1cf6lm#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.ttf?1cf6lm') format('truetype'),
		url('../fonts/icomoon/icomoon.woff?1cf6lm') format('woff'),
		url('../fonts/icomoon/icomoon.svg?1cf6lm#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	color: #282828;
  
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .icon-document:before {
	content: "\e900";
  }
  .icon-dollar:before {
	content: "\e901";
  }
  .icon-folders:before {
	content: "\e902";
  }
  .icon-graduate-hat:before {
	content: "\e903";
  }
  .icon-help-wheel:before {
	content: "\e904";
  }
  .icon-interface:before {
	content: "\e905";
  }
  .icon-news:before {
	content: "\e906";
  }
  .icon-search:before {
	content: "\e907";
  }
  .icon-shopping:before {
	content: "\e908";
  }
  .icon-travel:before {
	content: "\e909";
  }
  .icon-travel2:before {
	content: "\e90a";
  }
  