
//main styles

.main-wrapper{
	padding: 0 0 0 0;
	min-width: $min-width;
    width: 100%;
	position: relative;
	overflow: hidden;
	min-height:100%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	flex-direction:column;
    -webkit-flex-direction:column;
    -ms-flex-direction: column;
	justify-content:flex-start;	
    -webkit-justify-content:flex-start;	
    -ms-flex-pack: start;
}
.wrapper{
	min-width: $min-width;
	max-width: $max-width;
	padding: 0 40px 0 40px;
	margin: 0 auto;
	position: relative;
}

/* titles */
p{	
	@include font($base-font-size,$base-line-height,$text-color);
	font-weight: 400;	
}
h1{	
	@include font($font-size-h1,$line-height-h1,$title-color);
	font-family: $title-font-family;
	font-weight: 500;	
	letter-spacing: .4px;
}
h2{
	@include font($font-size-h2,$line-height-h2,$title-color);
	font-family: $title-font-family;
	font-weight: 500;	
}
h3{
	@include font($font-size-h3,$line-height-h3,$title-color);
	font-family: $title-font-family;
	font-weight: 500;
}
h4{
	@include font($font-size-h3,$line-height-h3,$title-color);
	font-family: $title-font-family;
	font-weight: 500;
}
h5{
	@include font($font-size-h3,$line-height-h3,$title-color);
	font-family: $title-font-family;
	font-weight: 500;
}

/* text position */
.text-left{text-align: left!important}
.text-center{text-align: center!important}
.text-right{text-align: right!important}
.nowrap{white-space: nowrap!important;}


		/* loader */
.loaded .main-wrapper{visibility:hidden;}
.icon-load{background:url(../img/loader.gif) no-repeat left top;width:40px;height:40px;position:fixed;left:50%;top:50%;margin-left:-20px;margin-left:-20px;display:none;}
.loaded .icon-load{display:block;}


/*
================================================================================
|                                     CONTENT                                 |
================================================================================
*/
.content{	
	min-width: $min-width;
	text-align:left;
    width: 100%;
	order: 2;
    -webkit-order: 2;
	flex-grow: 1;
    -webkit-flex-grow: 1;
}

.btn {
	display: inline-block;
	line-height: normal;
	text-align: center;
	position: relative;
	transition: $transition;
	background-color: $red;
	border: none;
	outline: none;
	padding: 12px 51px;
	color: #ffffff;
	border-radius: 3px;
	&:hover {
		background-color: #E84149;
	}
	&-blue {
		background-color: $blue;
		&:hover {
			background-color: #1D3777;
		}
	}
}

.form-control {
	background: $white;
	box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
	height: 70px;
	line-height: 70px;
	padding: 0 16px;
	border: none;
	outline: none;
	width: 100%;
	font-size: 16px;
	&::placeholder {
		color: $grey2;
		opacity: 1;
	}
	  
	&:-ms-input-placeholder {
		color: $grey2;
	}
	  
	&::-ms-input-placeholder {
		color: $grey2;
	}
}

.main-block {
	position: relative;
	padding-top: 160px;
	padding-bottom: 241px;
	z-index: 1;
	&:after {
		content: '';
		width: 100%;
		height: 140%;
		position: absolute;
		left: 0;
		top: 0;
		background: linear-gradient(180deg, #EFEFEF 0%, #FFFFFF 100%);
		transform: rotate(-180deg) skewY(-5deg);
		z-index: -1;
    	// transform-origin: 100%;
	}
	&__content {
		max-width: 562px;
		z-index: 11;
		h1 {
			color: $blue;
			margin-bottom: 8px;
		}
		span {
			font-size: 18px;
			line-height: 26px;
			color: $grey1;
			display: block;
			margin-bottom: 40px;
			letter-spacing: .05px;
		}
	}
	&__img {
		position: absolute;
		left: 43%;
		top: 0;
		z-index: 1;
	    object-fit: cover;
    	width: 100%;
	}
}

.box-field {
	position: relative;
	&__search {
		max-width: 469px;
		span {
			display: none;
		}
		button {
			background-color: transparent;
			box-shadow: none;
			outline: none;
			border: none;
			position: absolute;
			right: 8px;
			top: 8px;
			background: $red;
			border-radius: 3px;
			width: 54px;
			height: 54px;
			display: flex;
			align-self: center;
			justify-content: center;
			transition: $transition;
			i {
				color: $white;
				font-size: 30px;
				margin: auto;
			}
			&:hover {
				background-color: #E84149;
			}
		}
		.form-control {
			padding-right: 70px;
			&::-webkit-search-decoration,
			&::-webkit-search-cancel-button,
			&::-webkit-search-results-button,
			&::-webkit-search-results-decoration {
			  display: none; 
			}
		}
	}
}

.states-block {
	position: relative;
	z-index: 1;
	&:after {
		z-index: -1;
		position: absolute;
		top: -35px;
		left: 0;
		width: 100%;
		height: 110%;
		background-color: $red;
		content: '';
		transform: skewY(-5deg);
    	transform-origin: 100%;
	}
	&__content {
		background: $white;
		box-shadow: 0px 42px 62px rgba(0, 0, 0, 0.1);
		border-radius: 6px;
		padding: 80px 44px 72px;
		h2 {
			color: $blue;
		}
		p {
			color: $grey1;
			max-width: 505px;
			margin: auto;
			br {
				display: none;
			}
		}
		ul {
			margin-top: 32px;
			display: flex;
			flex-wrap: wrap;
			margin-left: -12px;
			margin-right: -12px;
			li {
				width: calc(25% - 24px);
				margin-left: 12px;
				margin-right: 12px;
				a {
					border-radius: 32px;
					padding: 8px;
					display: flex;
					align-items: center;
					font-size: 18px;
					line-height: 24px;
					color: #000;
					&:hover {
						background-color: $grey3;
					}
					img {
						width: 32px;
						height: 32px;
						margin-right: 18px;
					}
				}
			}
		}
	}
}

.derectories {
	padding-top: 96px;
	padding-bottom: 190px;
	color: $white;
	position: relative;
	z-index: 1;
	&:after {
		z-index: -1;
		position: absolute;
		top: 50px;
		left: 0;
		width: 100%;
		height: 78.5%;
		background-color: $blue;
		content: '';
		transform: skewY(-5deg);
    	transform-origin: 100%;
	}
	h2 {
		color: $white;
		font-size: 52px;
		line-height: 70px;
	}
	p {
		color: $white;
		max-width: 650px;
		margin: auto;
		font-size: 18px;
		line-height: 24px;
		opacity: 0.7;
	}
	&-items {
		margin-top: 59px;
	}
	&-item {
		display: flex;
		margin-bottom: 64px;
		padding: 30px 24px 24px 20px;
		margin-bottom: 10px;
		border-radius: 4px;
		&__icon {
			width: 40px;
			margin-right: 16px;
			margin-top: -7px;
			i {
				font-size: 38px;
			}
		}
		&__info {
			span {
				font-weight: 500;
				font-size: 22px;
				line-height: 24px;
				display: block;
				margin-bottom: 8px;
				color: $white;
			}
			p {
				opacity: 0.6;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
	&-items {
		&:nth-of-type(2) {
			.derectories-item {
				padding-left: 23px;
			}
		}
	}
}

@media screen and (min-width: 2000px) {
	.main-block__img {
	    left: 42.6%;
	}
	.states-block:after {
		top: -65px;
	}
	.derectories:after {
		top: 23px;
	}
}

@media screen and (max-width: 1600px) {
	.main-block__img {
		right: -53.4%;
		left: auto;
		width: auto;
	}
	.states-block:after {
		top: -19px;
	}
	.derectories:after {
		top: 72px;
	}
}

@media screen and (max-width: 1200px) {
	.main-block__img {
		right: -100%;
	}
}

@media screen and (max-width: 991px) {
	.main-block__img {
		right: -120%;
	}
	.states-block__content ul li {
		width: calc(50% - 24px);
	}
	.derectories-items {
		margin-top: 0px;
		&:first-of-type {
			margin-top: 32px;
		}
	}
	.derectories:after {
		height: 88.5%;
	}
}

@media screen and (max-width: 800px) {
	.main-block__img {
		right: -140%;
	}
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 32px;
		line-height: 42px;
	}
	.box-field__search {
		max-width: 100%;
	}
	.header ul.header-nav, .main-block__content {
		padding-left: 15px;
		padding-right: 15px;
	}
	.scroll-off {
		overflow: hidden;
	}
	.main-block {
		padding-top: 113px;
		padding-bottom: 276px;
	}
	.main-block__content span {
		font-size: 16px;
		line-height: 24px;
	}
	.main-block__content h1 {
		width: 98%;
	}
	.main-block__img {
		right: 0;
		top: auto;
		bottom: -2px;
		width: 100%;
		max-width: 320px;
	}
	h2 {
		font-size: 32px;
		line-height: 42px;
	}
	.states-block__content p, .derectories p, p {
		font-size: 14px;
		line-height: 20px;
	}
	.states-block {
		padding: 8px;
		background-color: $red;
	}
	.states-block:after {
		top: 0;
	}
	.states-block__content {
		padding: 24px 10px 34px;
	}
	.states-block__content ul li a {
		font-size: 14px;
		line-height: 20px;
		padding: 7px;
	}
	.states-block__content ul li a img {
		width: 16px;
		height: 16px;
		margin-right: 7px;
	}
	.states-block__content ul {
		margin: 0;
		margin-top: 13px;
	}
	.states-block__content ul li {
		width: 50%;
		margin: 0;
	}
	.derectories h2 {
		font-size: 32px;
		line-height: 42px;
	}
	.derectories {
		padding: 33px 0 56px;
		p {
			width: 96%;
		}
		&:after {
			top: 69px;
		}
	}
	.derectories-item__info span {
		font-size: 18px;
		line-height: 24px;
	}
	.derectories-item {
		padding: 0;
		margin-bottom: 25px;
	}
	.derectories-item__icon {
		margin-right: 5px;
		margin-top: 2px;
		margin-left: -9px;
	}
	.derectories-item__icon i {
		font-size: 32px;
	}
	.derectories-item__info p {
		margin-left: 2px;
	}
	.derectories-items:nth-of-type(2) .derectories-item {
		padding-left: 0;
	}
	.main-block {
		background: #fff;
	}
	.states-block__content p {
		br {
			display: block;
		}
	}
	.main-block__content {
		z-index: 11;
	}
}

@media screen and (max-width: 460px) {
	.derectories:after {
		height: 92.3%;
	}
	.main-block__img {
		max-width: 100%;
		position: relative;
		margin-top: 58px;
	}
	.main-block {
		padding-bottom: 0;
	}
	.box-field__search {
		padding: 8px;
		background: #FFFFFF;
		box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
		border-radius: 6px;
		.form-control {
			box-shadow: none;
			height: 54px;
			padding: 0 11px;
		}
	}
	.main-block__content span {
		margin-bottom: 13px;
	}
	.box-field__search button {
		position: relative;
		right: auto;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;
		border-radius: 3px;
		top: auto;
		margin-top: 11px;
		i {
			display: none;
		}
		span {
			display: block;
			font-size: 16px;
			line-height: 24px;
			color: #FFFFFF;
			margin-bottom: 0;
		}
	}
}

