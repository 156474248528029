/* reset */
$min-width: 320px; 
$max-width: 1200px; 
$base-bg: #fff; 
$base-font-size: 16px; 
$base-line-height: 24px; 
$base-font-family: 'Roboto', sans-serif; 
$custom-font-family: 'Roboto', sans-serif; 
$link-color: #100da9;
$transition: all 0.2s linear;

/* main variables */
$black: #282828;
$red: #FE4F58;
$blue: #2A4893;
$grey1: #6D7589;
$grey2: #B6BAC4;
$grey3: #F3F4F4;
$white: #fff;

//text, titles
$text-color: $black;
$title-color: $black;
$title-font-family: 'Poppins', sans-serif; 

$font-size-h1: 60px;
$line-height-h1: 64px;
$font-size-h2: 48px;
$line-height-h2: 60px;
$font-size-h3: 40px;
$line-height-h3: 48px;
$font-size-h3: 32px;
$line-height-h3: 42px;
$font-size-h3: 22px;
$line-height-h3: 24px;